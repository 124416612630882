<template>
  <create />
</template>

<script>
import Create from '@/components/pages/order_quest/Create';

export default {
  name: 'Edit',
  components: { Create }
};
</script>
